// @flow

import axios from 'axios';
import { baseURL } from '../config';

export const fetchCarriers = async (
    id: number = null,
    page: number = '',
    limit: number = '',
    sort?: string,
    condition?: string,
    search?: string
) => {
  const url = `${baseURL}/api/admin/carriers${
      id
          ? `/${id}`
          : `?page=${page || 1}&paginate=${limit || 700}&${
              sort ? `sort=${sort}` : `sort=`
          }&${condition ? condition : ''}&${search ? search : ''}`
  }`;

  try {
    let response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAvailableCarriers = async () => {
  const url = `${baseURL}/api/admin/carriers/available`;
  try {
    let response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sortCarriers = async (sort: string, dir: string) => {
  try {
    let response = await axios.get(
        `${baseURL}/api/admin/carriers?sort=${sort}&dir=${dir}`
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getStatuses = async () => {
  try {
    let response = await axios.get(`${baseURL}/api/admin/carriers/statuses`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCarrierStatusStatistics = async () => {

  try {
    let response = await axios.get(
        `${baseURL}/api/admin/statistics/carriers/count`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDrivers = async () => {
  try {
    let response = await axios.get(
        `${baseURL}/api/admin/statistics/carriers/drivers/all`);

    return response.data;
  } catch (error) {
    throw error;
  }
};
