import { useStyletron } from "baseui";
import { LabelXSmall, ParagraphSmall } from "baseui/typography";
import React from "react";
import { OrderPopupContainer } from "./styled-components";

function OrderPopup({ type, order }) {
  return (
    <>
      <OrderPopupContainer>
        {type === "pickup" ? (
          <PickupInfo order={order} />
        ) : type === "dropoff" ? (
          <DropoffInfo order={order} />
        ) : null}
      </OrderPopupContainer>
    </>
  );
}

export function PickupInfo({ order }) {
  const [css] = useStyletron();
  return (
    <section
      className={css({
        padding: "8px",
      })}
    >
      <section>
        <LabelXSmall
          className={css({
            fontWeight: "bolder",
            fontSize: "18px",
          })}
        >
          {order.order_origins[0]?.location_name}
        </LabelXSmall>
        <br />
        <LabelXSmall
          className={css({
            fontSize: "18px",
          })}
        >
          Contact Info
        </LabelXSmall>
        <ParagraphSmall
          className={css({
            fontSize: "16px",
          })}
        >
          Name: {order.order_origins[0]?.contact_name}
        </ParagraphSmall>
        <ParagraphSmall
          className={css({
            fontSize: "16px",
          })}
        >
          Phone: {order.order_origins[0]?.contact_phone}
        </ParagraphSmall>
      </section>
    </section>
  );
}

export function DropoffInfo({ order }) {
  const [css] = useStyletron();

  return (
    <section
      className={css({
        padding: "8px",
      })}
    >
      <section>
        <LabelXSmall
          className={css({
            fontWeight: "bolder",
            fontSize: "18px",
          })}
        >
          {order.order_destinations[0]?.location_name}
        </LabelXSmall>
        <br />
        <LabelXSmall
          className={css({
            fontSize: "18px",
          })}
        >
          Contact Info
        </LabelXSmall>
        <ParagraphSmall
          className={css({
            fontSize: "16px",
          })}
        >
          Name: {order.order_destinations[0]?.contact_name}
        </ParagraphSmall>
        <ParagraphSmall
          className={css({
            fontSize: "16px",
          })}
        >
          Phone: {order.order_destinations[0]?.contact_phone}
        </ParagraphSmall>
      </section>
    </section>
  );
}

export default OrderPopup;
