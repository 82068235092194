// @flow

import { showErrors } from '../helpers/errors';
import * as TYPES from './constants';

const OperatorsReducer = (
    state: any = {
      isLoading: false,
      message: '',
      operators: [],
      detail: '',
      reset: false,
      carriers_count: {},
      drivers_count: ''
    },
    action: any
) => {
  switch (action.type) {
    case TYPES.FETCH_CARRIERS:
      return {
        ...state,
        operators: action.payload.carriers.items,
        isLoading: false,
        sort: action.sort,
        dir: action.dir,
        per_page: action.payload.carriers.per_page,
        total:
            Math.ceil(
                action.payload.carriers.total / action.payload.carriers.paginate
            ),
        page: action.payload.carriers.page,
        reset: false
      };
    case TYPES.FETCH_AVAILABLE_CARRIERS:
      return {
        ...state,
        operators: action.payload.carriers.items,
        isLoading: false,
        sort: action.sort,
        dir: action.dir,
        per_page: action.payload.carriers.per_page,
        total:
            Math.ceil(
                action.payload.carriers.total / action.payload.carriers.paginate
            ),
        page: action.payload.carriers.page,
        reset: false
      };
    case TYPES.OPS_ERROR:
      showErrors(action.payload?.errors, action.payload?.detail);
      return {
        ...state,
        isLoading: false,
        detail: action.payload?.detail,
        close: true
      };
    case TYPES.OPS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case TYPES.GET_STATUSES:
      return {
        statuses: action.payload.statuses,
        isLoading: false
      };

    case TYPES.GET_CARRIER_STATUS_STATISTICS:
      return {
        ...state,
        carriers_count: action.payload
      };
    case TYPES.GET_DRIVER_COUNT:
      return {
        ...state,
        drivers_count: action.payload
      };
    default:
      return state;
  }
};

export default OperatorsReducer;
