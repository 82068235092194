/* eslint-disable */
// @flow

import React from "react";
import { useStyletron } from "baseui";
import withNav from "../hoc/with-nav";
import { useDispatch, useSelector } from "react-redux";
import { HeadingLarge } from "baseui/typography";
import { Button } from "baseui/button";
import { getPrices, getProducts } from "../products/actions";
import AddPrice from "./add";
import PriceTable from "./table";
import { useLocation } from "react-router";
import Loader from "../qui/loader";

function Prices(props) {
  const [css] = useStyletron();
  const [isOpen, setIsOpen] = React.useState(false);
  const [filteredPricing, setFilteredPricing] = React.useState([]);

  const dispatch = useDispatch();

  const { prices, isLoading } = useSelector((state) => state.product);

  React.useEffect(() => {
    dispatch(getPrices());
    dispatch(getProducts());
    // dispatch(getGlobalVariants("currencies"));
    // dispatch(getGlobalVariants("countries"));
    // dispatch(getUoms());
  }, [props.type]);

  React.useEffect(() => {
    const newPrices = prices.filter((item) => item.type_id === props.type);

    setFilteredPricing(newPrices);
  }, [props.type, prices]);

  return (
    <>
      <div
        className={css({
          margin: "180px auto 0px auto",
          "@media screen and (max-width: 600px)": {
            margin: "80px auto 0px auto",
          },
        })}
      >
        <div
          className={css({
            display: "flex",
            justifyContent: "space-between",
          })}
        >
          <HeadingLarge $style={{ margin: "0px" }}>Price List</HeadingLarge>
          <Button shape="pill" onClick={() => setIsOpen(true)}>
            Add Prices
          </Button>
        </div>
      </div>
      <AddPrice
        isOpen={isOpen}
        pricing_type={props.type}
        handleIsOpen={setIsOpen}
        dataType={props.type}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <PriceTable prices={filteredPricing} pricing_type={props.type} />
      )}
    </>
  );
}

export const PricesPage = withNav(Prices);
