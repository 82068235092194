import React from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../config";
import {useStyletron} from "baseui";
import {LabelMedium, LabelSmall, ParagraphSmall} from "baseui/typography";
import {Card, StyledBody} from "baseui/card";
import {StatefulPopover} from "baseui/popover";
import moment from "moment";
import momentTimezone from "moment-timezone";

const client = axios.create({
    baseURL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    },
});


function OrderAssignments() {
    const {order} = useSelector((state) => state.order);
    const [assignments, setAssignments] = React.useState(null);
    const [css] = useStyletron()

    let orderId = order.id;
    React.useEffect(() => {
        client.get(`/api/admin/orders/${orderId}/assignments`).then((response) => {
            setAssignments(response.data);
        });
    }, []);


    if (!assignments) return <LabelSmall>No assignments</LabelSmall>;
    const allAssignments = assignments?.assignments

    const listAssignments = allAssignments.map((assgnmnt, index)=>
        <Card className={css({
            marginBottom: '0.5rem',
            marginRight: '0.5rem',
            width: "45%"
        })}>
            <StyledBody>
                <ParagraphSmall>
                    Driver: {assgnmnt.driver?.name}
                </ParagraphSmall>
                {assgnmnt.driver?.phone && (
                    <ParagraphSmall>
                        Phone: {assgnmnt.driver?.phone}
                    </ParagraphSmall>
                )}
                {assgnmnt?.vehicle && (
                    <ParagraphSmall>
                        Reg No.: {assgnmnt.vehicle?.licence_plate}
                    </ParagraphSmall>
                )}

                {assgnmnt?.user?.login && (
                    <ParagraphSmall>
                        Assigned by: System
                    </ParagraphSmall>
                )}

                {assgnmnt?.user?.personnel && (
                    <ParagraphSmall>
                        Assigned by: {assgnmnt?.user?.personnel?.name}
                    </ParagraphSmall>
                )}

                <StatefulPopover
                    content={() => (
                        <div className={css({
                            minWidth: "250px",
                            backgroundColor:"#ffffff",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                            padding:"0.5rem",
                            maxWidth:"280px"
                        })}>
                            <LabelMedium className={css({
                                marginBottom: '0.25rem',
                                fontWeight: '600'
                            })}>Driver Details</LabelMedium>
                            <LabelSmall>Name: <span>{assgnmnt.driver.name}</span></LabelSmall>
                            <LabelSmall>Phone: <span>{assgnmnt.driver?.phone}</span></LabelSmall>
                            <LabelSmall className={css({
                                marginBottom: '0.5rem'
                            })}>Email: <span>{assgnmnt.driver?.email}</span></LabelSmall>

                            <LabelMedium className={css({
                                marginBottom: '0.25rem',
                                fontWeight: '600'
                            })}>Vehicle Details</LabelMedium>
                            <LabelSmall className={css({
                                marginBottom: '0.5rem'
                            })}>Reg No: <span>{assgnmnt.vehicle?.licence_plate}</span></LabelSmall>

                            <LabelMedium className={css({
                                marginBottom: '0.25rem',
                                fontWeight: '600'
                            })}>Assignment Details</LabelMedium>

                            <LabelSmall> Assigned At: {moment(
                                momentTimezone.tz(
                                    assgnmnt?.changed_at,
                                    momentTimezone.tz.guess()
                                )
                            ).format("lll")}</LabelSmall>

                            {assgnmnt.user?.personnel && <LabelSmall>Assigned By: <span>{assgnmnt.user?.personnel.name}</span></LabelSmall>}
                            {assgnmnt.user?.login && <LabelSmall>Assigned: System</LabelSmall>}
                            {assgnmnt.distance && <LabelSmall>Distance: <span>{assgnmnt.distance} {assgnmnt.distance_unit}</span></LabelSmall>}
                        </div>
                    )}
                >
                    <button className={css({
                        lineHeight: "12px",
                        fontSize: "12px",
                        cursor:"pointer",
                        marginTop: "1px",
                        marginRight: "2px",
                        top:"0",
                        right:"0",
                        border:"none",
                        background:"transparent",
                        color: "#ecc910"
                    })}>See More Details</button>
                </StatefulPopover>
            </StyledBody>
        </Card>
    )
    return (
        <div className={css({
            position: "relative",
            width: "100%",
            display: "flex",
            flexWrap:"wrap",
            padding: "0.5rem",
            background: "#FFFFFF",
            maxHeight: "400px",
            overflow: "auto",
            justifyContent:"center"
        })}>{listAssignments}</div>
    )
}

export default OrderAssignments
