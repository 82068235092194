export const GET_ORDERS = "GET_ORDERS";
export const LIST_ORDERS = "LIST_ORDERS";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const LIST_ORDER = "LIST_ORDER";
export const ORDER_ERROR = "ORDER_ERROR";
export const ORDER_STATUS_UPDATE = "ORDER_STATUS_UPDATE";
export const UPDATE = "UPDATE_ORDER";
export const UPDATE_ERROR = "UPDATE_ORDER_ERROR";
export const DISPUTE = "DISPUTE";
export const SYNC_ORDER = "SYNC_ORDER";
export const GET_STATUS = "GET_STATUS";
export const CANCEL_ORDER = "CANCEL_ORDER";

export const PERSONNEL_ROLE = {
  admin: "admin",
  user: "user",
  guest: "guest",
};

export const ORDER_STATUS = {
  created: "created",
  finalizingDetail: "finalizing_detail",
  matchingCarriers: "matching_carriers",
  carrierConfirmed: "carrier_confirmed",
  driverAssigned: "driver_assigned",
  inTransitToPickup: "in_transit_to_pickup",
  atOrigin: "at_origin",
  inTransit: "in_transit",
  atDestination: "at_destination",
  delivered: "delivered",
  cancelled: "cancelled",
};
