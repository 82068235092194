import { useStyletron } from "baseui";
import React from "react";
import { useHistory } from "react-router";
import { Button } from "baseui/button";

const GPSICON = (
  <svg
    viewBox="0 0 512 512"
    style={{ width: "60px" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m8.828125 282.484375h45.902344c12.0625 91.066406 83.71875 162.722656 174.785156 174.785156v45.902344c.015625 4.871094 3.960937 8.8125 8.828125 8.828125h35.3125c4.867188-.015625 8.8125-3.957031 8.828125-8.828125v-45.902344c91.066406-12.0625 162.722656-83.71875 174.785156-174.785156h45.902344c4.871094-.015625 8.8125-3.960937 8.828125-8.828125v-35.3125c-.015625-4.867188-3.957031-8.8125-8.828125-8.828125h-45.902344c-12.0625-91.066406-83.71875-162.722656-174.785156-174.785156v-45.902344c-.015625-4.871094-3.960937-8.8125-8.828125-8.828125h-35.3125c-4.867188.015625-8.8125 3.957031-8.828125 8.828125v45.902344c-91.066406 12.0625-162.722656 83.71875-174.785156 174.785156h-45.902344c-4.871094.015625-8.8125 3.960937-8.828125 8.828125v35.3125c.015625 4.867188 3.957031 8.8125 8.828125 8.828125zm247.171875-176.554687c82.878906 0 150.070312 67.191406 150.070312 150.070312s-67.191406 150.070312-150.070312 150.070312-150.070312-67.191406-150.070312-150.070312c.117187-82.832031 67.238281-149.953125 150.070312-150.070312zm0 0" />
    <path d="m326.621094 256c0 39.003906-31.617188 70.621094-70.621094 70.621094s-70.621094-31.617188-70.621094-70.621094 31.617188-70.621094 70.621094-70.621094 70.621094 31.617188 70.621094 70.621094zm0 0" />
  </svg>
);

const INFOICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    style={{ width: "60px", color: "#000" }}
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const LINKICON = (
  <svg
    style={{ width: "60px", color: "#000" }}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M10 6HeadingXSmalla2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
    />
  </svg>
);

function ControlPanel({ resetPosition, togglePopup, orderId }) {
  const [css] = useStyletron();

  const history = useHistory();

  return (
    <div
      className={css({
        margin: "4px",
        position: "absolute",
        top: "0px",
      })}
    >
      <section
        className={css({
          display: "grid",
          gridTemplateColumns: "auto",
          gridTemplateRows: "repeat(3, auto)",
          gridColumnGap: "0px",
          gridRowGap: "4px",
        })}
      >
        <Button
          size="mini"
          shape="circle"
          kind="tertiary"
          className={css({
            background: "#fff",
            padding: "8px",

            ":hover": {
              background: "#fff",
              opacity: "0.8",
            },
          })}
          onClick={() => resetPosition()}
        >
          {GPSICON}
        </Button>
        <Button
          size="mini"
          kind="tertiary"
          shape="circle"
          className={css({
            background: "#fff",
            padding: "6px",

            ":hover": {
              background: "#fff",
              opacity: "0.8",
            },
          })}
          onClick={() => togglePopup()}
        >
          {INFOICON}
        </Button>
        <Button
          size="mini"
          kind="tertiary"
          shape="circle"
          className={css({
            background: "#fff",
            padding: "6px",

            ":hover": {
              background: "#fff",
              opacity: "0.8",
            },
          })}
          onClick={() => history.push(`/order/${orderId}`)}
        >
          {LINKICON}
        </Button>
        {/* <Button
          size="mini"
          shape="circle"
          className={css({
            background: "#fff",
            padding: "6px",

            ":hover": {
              background: "#fff",
              opacity: "0.8",
            },
          })}
          onClick={() => resetPosition()}
        >
          {GPSICON}
        </Button> */}
      </section>
    </div>
  );
}

export default ControlPanel;
