/* eslint-disable  react-hooks/exhaustive-deps */
// @flow

import * as React from "react";
import { Drawer } from "baseui/drawer";

type DrawerContent = {
  renderContent: () => React.ReactElement,
  isOpen: boolean,
  setIsOpen: Function,
  anchor?: string,
  size?: string,
};

const ContentDrawer: React.FC<DrawerContent> = (props): React.ReactElement => {
  return (
    <Drawer
      isOpen={props.isOpen}
      autoFocus
      onClose={() => props.setIsOpen(false)}
      anchor={props.anchor || "right"}
      size={props.size || "default"}
    >
      {props.renderContent()}
    </Drawer>
  );
};

export default ContentDrawer;
