import { LabelXSmall, ParagraphSmall } from "baseui/typography";
import React from "react";
import { useStyletron } from "styletron-react";
import moment from "moment";

type Props = {
  price: any,
};

const Details: React.FC<Props> = (props): React.ReactElement => {
  const [css] = useStyletron();

  return (
    <div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>Country:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          {props.price.country_code && props.price.country_code}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>Currency:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          {props.price.currency_code && props.price.currency_code}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>Type:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          {props.price.type_id}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>Start Date:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          {moment(props.price.start_date).format("MMM Do YY")}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>End Date:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          {moment(props.price.end_date).format("MMM Do YY")}
        </ParagraphSmall>
      </div>
      <hr />
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>Cost Base Price:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          KES.{props.price.cost_base_price}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>Cost Unit Price:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          KES.{props.price.cost_unit_price}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>List Base Price:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          KES.{props.price.list_base_price}
        </ParagraphSmall>
      </div>
      <div
        className={css({
          display: "flex",
          height: "40px",
          placeItems: "center",
          width: "300px",
          justifyContent: "left",
        })}
      >
        <LabelXSmall>List Unit Price:</LabelXSmall>
        <ParagraphSmall $style={{ marginLeft: "16px" }}>
          KES.{props.price.list_unit_price}
        </ParagraphSmall>
      </div>
    </div>
  );
};

export default Details;
