import React from 'react';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { Avatar } from 'baseui/avatar';
import { Button } from 'baseui/button';
import { Tabs, Tab } from 'baseui/tabs-motion';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ArrowLeft from 'baseui/icon/arrow-left';
import { fetchAvailableCarriers } from '../operators/service';
import OrderStatus from './order-status';
import { DetailsContainer, OrderDetails } from './styled-components';
import {
	LabelLarge,
	ParagraphSmall,
	ParagraphMedium,
	LabelMedium,
	LabelXSmall,
	ParagraphXSmall
} from 'baseui/typography';
import Loader from '../qui/loader';
import { useStyletron } from 'baseui';
import {
	fetchAvailableCarriersAction,
	fetchCarriersAction
} from '../operators/actions';
import {
	fetchAvailableVehiclesActions,
	fetchVehiclesActions
} from '../vehicles/actions';
import { capitalizer } from '../helpers/string';
import { useState } from 'react';
import PaymentDispute from './payment-dispute';
import Transactions from './transactions';
import { fetchSingleOrderAction, syncOrder } from './actions';
import { getDateDiffInMins } from '../helpers/date';
import OrderHistory from './order-history';
import { orderDurationChecker } from '../helpers/order-duration';
import OrderAssignments from './order-assignments';
import OrderUploads from './order-uploads';

export default function SingleOrder() {
	const {
		order,
		sideLoading,
		history: orderStatusHistory
	} = useSelector((state) => state.order);
	const [activeKey, setActiveKey] = useState('0');
	const params = useParams();

	const [css] = useStyletron();
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(fetchAvailableCarriersAction());
		dispatch(fetchAvailableVehiclesActions());
	}, []);

	React.useEffect(() => {
		if (params.order_id) {
			dispatch(fetchSingleOrderAction(params.order_id));
		}
	}, []);

	React.useEffect(() => {
		let interval;

		if (params.order_id) {
			interval = setInterval(() => {
				dispatch(syncOrder(params.order_id));
			}, 10000);
		}

		return () => clearInterval(interval);
	}, []);

	const history = useHistory();

	return (
		<DetailsContainer>
			<Button
				startEnhancer={ArrowLeft}
				onClick={() => history.push(`/orders?currentFilter=0`)}
				className={css({
					background: 'none !important',
					color: '#ffa020 !important',
					':hover': {
						background: '#e4e4e4 !important'
					}
				})}
			>
				Back to orders
			</Button>
			<OrderDetails
				className={css({
					padding: '20px 0px'
				})}
			>
				{sideLoading && !order?.shipper ? (
					<Loader/>
				) : (
					<>
						<section>
							<section
								className={css({
									display: 'flex',
									justifyContent: 'space-between'
								})}
							>
								<LabelLarge>{order?.order_number}</LabelLarge>
								<section>
									<LabelLarge
										$style={{
											'@media only screen and (max-width: 523px)': {
												fontSize: '14px !important'
											}
										}}
									>
										Order Status: {capitalizer(order?.order_status_id)}
									</LabelLarge>
									<LabelLarge
										$style={{
											'@media only screen and (max-width: 523px)': {
												fontSize: '14px !important'
											}
										}}
									>
										Payment: {capitalizer(order?.invoice_status_id)}
									</LabelLarge>
								</section>
							</section>
							<div
								className={css({
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									padding: '4px 0px'
								})}
							>
								<div className={css({})}>
									<div
										className={css({
											minWidth: '1fr'
										})}
									>
										<ParagraphSmall>
											Pickup Date{' '}
											{moment(
												momentTimezone.tz(
													order?.order_origins &&
													order?.order_origins[0]?.pickup_date,
													momentTimezone.tz.guess()
												)
											).format('lll')}
										</ParagraphSmall>
									</div>
								</div>
								<div>
									{/* <div
                    className={css({
                      minWidth: "1fr",
                    })}
                  >
                    <ParagraphSmall $style={{ textAlign: "right" }}>
                      {moment(
                        momentTimezone.tz(
                          (order?.order_origins &&
                            order?.order_origins[0]?.pickup_date) ||
                            order?.date_order,
                          momentTimezone.tz.guess()
                        )
                      ).format("lll")}
                    </ParagraphSmall>
                  </div> */}
								</div>
							</div>

							<div
								className={css({
									display: 'flex',
									justifyContent: 'space-between',
									width: '100%',
									padding: '4px 0px',
									flexWrap: 'wrap'
								})}
							>
								<div
									className={css({
										boxSizing: 'border-box',
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
										'@media only screen and (max-width: 523px)': {
											flexDirection: 'row',
											boxSizing: 'border-box',
											display: 'flex',
											WebkitBoxOrient: 'horizontal',
											WebkitBoxDirection: 'normal',
											alignItems: 'left',
											WebkitBoxAlign: 'left',
											justifyContent: 'space-between',
											width: '100%',
											marginTop: '8px',
											fontSize: '8px !important'
										}
									})}
								>
									<LabelLarge
										$style={{
											'@media only screen and (max-width: 523px)': {
												fontSize: '14px !important'
											}
										}}
									>
										Vehicle: {order?.order_lines && order?.order_lines[0].name}
									</LabelLarge>
								</div>
								<div>
									<div>
										<div
											className={css({
												display: 'grid',
												gridTemplateColumns: '1fr',
												gridTemplateRows: `repeat(${1} 1fr)`,
												gridColumnGap: '0px',
												gridRowGap: '0px'
											})}
										>
											<div
												className={css({
													display: 'flex',
													placeItems: 'start'
												})}
											>
												<div className={css({marginLeft: '15px'})}>
													<div
														className={css({
															display: 'grid',
															gridTemplateColumns: '18px 1fr',
															gridTemplateRows:
																'minmax(18px, 34px) 1fr minmax(18px, 34px)',
															gridColumnGap: '8px',
															gridRowGap: '9px'
														})}
													>
														<svg
															fill="none"
															height="24"
															viewBox="0 0 24 24"
															width="24"
															xmlns="http://www.w3.org/2000/svg"
															className={css({
																flex: '0 0 auto'
															})}
														>
															<circle
																cx="12"
																cy="12"
																fill="black"
																r="9"
															></circle>
															<circle
																cx="12"
																cy="12"
																fill="white"
																r="3"
															></circle>
														</svg>
														<section>
															<ParagraphMedium $style={{margin: '0px'}}>
																{order?.order_origins &&
																	order?.order_origins[0]?.address}
															</ParagraphMedium>
														</section>

														{order?.order_destinations &&
															order?.order_destinations.map((destination) => (
																<>
																	<svg
																		fill="none"
																		height="24"
																		viewBox="0 0 24 24"
																		width="24"
																		xmlns="http://www.w3.org/2000/svg"
																		className={css({
																			flex: '0 0 auto'
																		})}
																	>
																		<rect
																			fill="black"
																			height="18"
																			width="18"
																			x="3"
																			y="3"
																		></rect>
																		<rect
																			fill="white"
																			height="6"
																			width="6"
																			x="9"
																			y="9"
																		></rect>
																	</svg>
																	<section>
																		<ParagraphMedium $style={{margin: '0px'}}>
																			{destination.address}
																		</ParagraphMedium>
																	</section>
																</>
															))}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <div
                className={css({
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  "@media only screen and (max-width: 523px)": {
                    flexDirection: "row",
                    boxSizing: "border-box",
                    display: "flex",
                    WebkitBoxOrient: "horizontal",
                    WebkitBoxDirection: "normal",
                    alignItems: "left",
                    WebkitBoxAlign: "left",
                    justifyContent: "space-between",
                    width: "100%",
                    marginTop: "8px",
                    fontSize: "8px !important",
                  },
                })}
              >
                <LabelLarge
                  $style={{
                    "@media only screen and (max-width: 523px)": {
                      fontSize: "14px !important",
                    },
                  }}
                >
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency:
                      (order?.order_lines &&
                        order?.order_lines[0]?.currency_code) ||
                      "KES",
                  }).format(
                    order?.order_lines && order?.order_lines[0]?.price_total
                  )}
                </LabelLarge>
              </div> */}
							<div
								className={css({
									boxSizing: 'border-box',
									// display: "flex",
									// justifyContent: "left",
									marginTop: '16px',
									width: '1fr',
									'@media only screen and (max-width: 523px)': {
										flexDirection: 'row',
										boxSizing: 'border-box',
										display: 'flex',
										WebkitBoxOrient: 'horizontal',
										WebkitBoxDirection: 'normal',
										alignItems: 'left',
										WebkitBoxAlign: 'left',
										justifyContent: 'left',
										width: '100%',
										marginTop: '16px',
										fontSize: '8px !important',
										flexWrap: 'wrap'
									}
								})}
							>
								<LabelLarge
									$style={{
										'@media only screen and (max-width: 523px)': {
											width: '100%',
											fontSize: '14px !important'
										}
									}}
								>
									Total amount:{' '}
									{new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency:
											(order.order_lines &&
												order.order_lines[0]?.currency_code) ||
											'KES'
									}).format(order.total_price)}
								</LabelLarge>
								<br/>
								<LabelLarge
									$style={{
										'@media only screen and (max-width: 523px)': {
											width: '100%',
											fontSize: '14px !important'
										}
									}}
								>
									Paid:{' '}
									{new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency:
											(order.order_lines &&
												order.order_lines[0]?.currency_code) ||
											'KES'
									}).format(
										order.order_lines &&
										parseInt(order.order_lines[0]?.price_total, 10) -
										parseInt(
											order.order_lines && order.total_outstanding,
											10
										)
									)}
								</LabelLarge>
								<br/>
								<LabelLarge
									$style={{
										'@media only screen and (max-width: 523px)': {
											width: '100%',
											fontSize: '14px !important'
										}
									}}
								>
									Outstanding:{' '}
									{new Intl.NumberFormat('en-US', {
										style: 'currency',
										currency:
											(order.order_lines &&
												order.order_lines[0]?.currency_code) ||
											'KES'
									}).format(order.order_lines && order.total_outstanding)}
								</LabelLarge>
								<br/>
								<LabelLarge
									$style={{
										'@media only screen and (max-width: 523px)': {
											width: '100%',
											fontSize: '14px !important'
										}
									}}
								>
									Payment Status: {capitalizer(order?.invoice_status_id)}
								</LabelLarge>
								<br/>
								<LabelLarge
									$style={{
										'@media only screen and (max-width: 523px)': {
											width: '100%',
											fontSize: '14px !important'
										}
									}}
								>
									Percentage Paid:{' '}
									{parseFloat(
										((parseInt(order.total_price, 10) -
												parseInt(order.total_outstanding, 10)) /
											parseInt(order.total_price, 10)) *
										100
									).toFixed(2)}
									%
								</LabelLarge>
							</div>
							<br/>
							<LabelLarge
								$style={{
									'@media only screen and (max-width: 523px)': {
										fontSize: '14px !important'
									}
								}}
							>
								<span>Placed on: </span>
								{moment(
									momentTimezone.tz(
										order?.created_at,
										momentTimezone.tz.guess()
									)
								).format('lll')}
							</LabelLarge>
							<br/>
							<LabelLarge
								$style={{
									'@media only screen and (max-width: 523px)': {
										fontSize: '14px !important'
									}
								}}
							>
								<span>Pickup date: </span>
								{moment(
									momentTimezone.tz(
										order?.order_origins &&
										order?.order_origins[0]?.pickup_date,
										momentTimezone.tz.guess()
									)
								).format('lll')}
							</LabelLarge>
							<br/>
							{(order.order_status_id === 'delivered' ||
								order.order_status_id === 'cancelled') && (
								<LabelLarge
									$style={{
										'@media only screen and (max-width: 523px)': {
											fontSize: '14px !important'
										}
									}}
								>
									<span>Duration:</span>
									{orderDurationChecker(order)?.text} [
									{orderDurationChecker(order)?.message}]
								</LabelLarge>
							)}
							<br/>
							{order?.order_destinations &&
								order?.order_destinations[0]?.dropoff_date && (
									<LabelLarge
										$style={{
											'@media only screen and (max-width: 523px)': {
												fontSize: '14px !important'
											}
										}}
									>
										<span>Delivered date: </span>
										{
											moment(
												momentTimezone.tz(
													order?.order_destinations[0]?.dropoff_date,
													momentTimezone.tz.guess()
												)
											).format('lll')
										}
									</LabelLarge>
								)}
						</section>
						<section
							className={css({
								flexDirection: 'column',
								justifyContent: 'flex-start',
								display: 'flex'
							})}
						>
							<LabelLarge
								$style={{
									'@media only screen and (max-width: 523px)': {
										fontSize: '14px !important'
									}
								}}
							>
								Commodity:
							</LabelLarge>
							<LabelLarge
								$style={{
									'@media only screen and (max-width: 523px)': {
										fontSize: '14px !important'
									}
								}}
							>
								{
									order?.order_loads && order?.order_loads[0]?.commodity
								}
							</LabelLarge>
						</section>
						<section
							className={css({
								display: 'flex',
								justifyContent: 'space-between',
								placeItems: 'center',
								borderBottom: '1px solid #dcdada',
								borderTop: '1px solid #dcdada',
								flexWrap: 'wrap',
								'@media (max-width: 600px)': {
									justifyContent: 'left'
								}
							})}
						>
							<section
								className={css({
									padding: '8px 0px'
								})}
							>
								<LabelLarge>Shipper</LabelLarge>
								<section
									className={css({
										display: 'flex',
										placeItems: 'center'
									})}
								>
									{order?.shipper && (
										<Avatar
											name={order?.shipper?.name}
											size={'64px'}
											key={order?.shipper?.email}
											overrides={{
												Root: {
													style: ({$theme}) => ({
														backgroundColor: '#ffa020'
													})
												}
											}}
										/>
									)}
									<section
										className={css({
											marginLeft: '8px'
										})}
									>
										<LabelMedium>{order?.shipper?.name}</LabelMedium>
										<LabelXSmall>{order?.shipper?.phone}</LabelXSmall>
									</section>
								</section>
							</section>
						</section>

						<section
							className={css({
								display: 'flex',
								justifyContent: 'space-between',
								placeItems: 'center',
								borderBottom: '1px solid #dcdada',
								borderTop: '1px solid #dcdada',
								flexWrap: 'wrap',
								'@media (max-width: 600px)': {
									justifyContent: 'left'
								}
							})}
						>
							{order?.driver_id && (
								<section
									className={css({
										padding: '8px 0px'
									})}
								>
									<LabelLarge>Driver Assigned</LabelLarge>
									<section
										className={css({
											display: 'flex',
											placeItems: 'center'
										})}
									>
										<Avatar
											name={order?.driver.name}
											size={'64px'}
											key={order?.driver_id}
										/>
										<section
											className={css({
												marginLeft: '8px'
											})}
										>
											<LabelMedium>{order?.driver.name}</LabelMedium>
											<LabelXSmall>{order?.driver.phone}</LabelXSmall>
										</section>
									</section>
								</section>
							)}
							{order?.vehicle_id && (
								<section
									className={css({
										padding: '8px 0px'
									})}
								>
									<LabelLarge>Vehicle Assigned</LabelLarge>
									<section
										className={css({
											display: 'flex',
											placeItems: 'center'
										})}
									>
										{order?.order_lines.length > 0 && (
											<img
												src={
													order.order_lines[0]?.name === 'Motorcycle'
														? 'https://res.cloudinary.com/quatrix/image/upload/v1630308043/assets/web/vehicles/v3/bike.svg'
														: order.order_lines[0]?.name === 'car'
															? 'https://res.cloudinary.com/quatrix/image/upload/v1630308041/assets/web/vehicles/v3/car.svg'
															: order.order_lines[0]?.name === 'pickup'
																? 'https://res.cloudinary.com/quatrix/image/upload/v1630308041/assets/web/vehicles/v3/pickup.svg'
																: order.order_lines[0]?.name === 'van'
																	? 'https://res.cloudinary.com/quatrix/image/upload/v1630308041/assets/web/vehicles/v3/small_van.svg'
																	: order.order_lines[0]?.name === 'foot'
																		? 'https://res.cloudinary.com/quatrix/image/upload/v1630308044/assets/web/vehicles/v3/walker.svg'
																		: 'https://res.cloudinary.com/quatrix/image/upload/v1630308038/assets/web/vehicles/v3/5T.svg'
												}
												className={css({
													width: '70px',
													margin: '0px'
												})}
												alt=""
											/>
										)}
										<section
											className={css({
												marginLeft: '8px'
											})}
										>
											<LabelMedium>{order?.vehicle.licence_plate}</LabelMedium>
										</section>
									</section>
								</section>
							)}
						</section>
						<Tabs
							activeKey={activeKey}
							onChange={({activeKey}) => {
								setActiveKey(activeKey);
							}}
							activateOnFocus
						>
							<Tab title="Order Update">
								<OrderStatus/>
							</Tab>

							<Tab title="Order History">
								<OrderHistory/>
							</Tab>
							{order?.invoice_status_id !== 'paid' && (
								<Tab title="Payment Processing">
									<PaymentDispute order={order}/>
								</Tab>
							)}
							{order?.order_transactions && (
								<Tab title="Transactions">
									<Transactions transactions={order?.order_transactions}/>
								</Tab>
							)}
							<Tab title="Notes">
								<ParagraphSmall>{order?.note}</ParagraphSmall>
								<ParagraphSmall>
									Pickup Notes:
									{order && order.id && order?.order_origins[0]?.note}
								</ParagraphSmall>
								<ParagraphSmall>
									Dropoff Notes:
									{order && order.id && order?.order_destinations[0]?.note}
								</ParagraphSmall>
							</Tab>
							<Tab title="Tracking & Contacts">
								{order?.order_status_id !== 'delivered' && (
									<>
										<Link
											className={css({
												color: '#0c9cee',
												textDecoration: 'none',
												fontWeight: 'bold'
											})}
											to={`/order/${order?.id}/tracking`}
										>
											<Button
												className={css({
													color: '#0c9cee',
													textDecoration: 'none',
													fontWeight: 'bold'
												})}
												kind="tertiary"
											>
												Track this order
											</Button>
										</Link>
										<hr/>
									</>
								)}
								<section
									className={css({
										display: 'flex',
										justifyContent: 'space-between',
										flexWrap: 'wrap'
									})}
								>
									<section>
										<LabelMedium>Pickup Contact</LabelMedium>
										{order?.order_origins && (
											<ParagraphSmall>
												Name: {order?.order_origins[0]?.contact_name}
											</ParagraphSmall>
										)}
										{order?.order_origins && (
											<ParagraphSmall>
												Phone: {order?.order_origins[0]?.contact_phone}
											</ParagraphSmall>
										)}
										{order?.order_origins && (
											<ParagraphSmall>
												Email: {order?.order_origins[0]?.contact_email}
											</ParagraphSmall>
										)}
									</section>
									<section>
										<LabelMedium>Dropoff Contact</LabelMedium>
										{order?.order_destinations && (
											<ParagraphSmall>
												Name: {order?.order_destinations[0]?.contact_name}
											</ParagraphSmall>
										)}
										{order?.order_destinations && (
											<ParagraphSmall>
												Phone: {order?.order_destinations[0]?.contact_phone}
											</ParagraphSmall>
										)}
										{order?.order_destinations && (
											<ParagraphSmall>
												Email:{' '}
												{order?.order_destinations[0]?.contact_email || 'N/A'}
											</ParagraphSmall>
										)}
									</section>
								</section>
							</Tab>
							<Tab title="Assignments">
								<OrderAssignments/>
							</Tab>
							<Tab title="Uploads">
								{/*<OrderHistory />*/}
								<OrderUploads/>
							</Tab>
						</Tabs>
					</>
				)}
			</OrderDetails>
		</DetailsContainer>
	);
}
