/* eslint-disable */

import { FormControl } from "baseui/form-control";
import { HeadingSmall } from "baseui/typography";
import { Input } from "baseui/input";
import { DatePicker } from "baseui/datepicker";
import React, { useEffect } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { Checkbox } from "baseui/checkbox";
import { useDispatch, useSelector } from "react-redux";
import ContentDrawer from "../single-operator/content-drawer";
import { Select } from "baseui/select";
import { Button } from "baseui/button";
import { addPrices } from "../products/actions";
import Tooltip from "../qui/tooltip";
import * as CONSTANTS from "../constants";

type Props = {
  isOpen: boolean,
  handleIsOpen: (boolean) => void,
  pricing_type: string,
  dataType: string,
};

function AddPrice(props: Props) {
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    current_product: [],
    current_currency: [],
    current_country: [],
    current_date: [],
    base_distance: 0,
    max_distance: 0,
    min_distance: 0,
    cost_base_price: 0,
    cost_unit_price: 0,
    list_base_price: 0,
    list_unit_price: 0,
    price_surcharge: 0,
    price_discount: 0,
    is_active: false,
    weight_unit: [],
    weight: 0,
    distance_unit: [],
  });

  const [payload, setPayload] = React.useState({
    shipper_id: null,
    product_id: null,
    uom_id: 3,
    currency_code: "KES",
    country_code: "KE",
    date_start: null,
    date_end: null,
    base_distance: 0,
    max_distance: 0,
    min_distance: 0,
    cost_base_price: 0,
    cost_unit_price: 0,
    list_base_price: 0,
    list_unit_price: 0,
    price_surcharge: 0,
    price_discount: 0,
    is_active: false,
    type_id: props.pricing_type,
    weight_unit: "",
    weight: 0,
    distance_unit: "",
  });

  const { saving, close, products, currencies, countries, price } = useSelector(
    (state) => state.product
  );

  function onSubmit() {
    dispatch(
      addPrices({
        ...payload,
        start_date: moment(state.current_date[0] || new Date()).format("L"),
        end_date: moment(state.current_date[1] || new Date()).format("L"),
        type_id: props.pricing_type,
      })
    );
  }

  function setData(key, value) {
    setPayload((prev) => ({ ...prev, [key]: value }));
  }

  const { handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  useEffect(() => {
    if (props.isOpen) {
      setState({
        current_product: [],
        current_currency: [],
        current_country: [],
        current_date: [],
        base_distance: 0,
        max_distance: 0,
        min_distance: 0,
        cost_base_price: 0,
        cost_unit_price: 0,
        list_base_price: 0,
        list_unit_price: 0,
        price_surcharge: 0,
        price_discount: 0,
        is_active: false,
        weight_unit: [],
        weight: 0,
        distance_unit: "",
      });
    }
  }, [props.isOpen]);

  function renderForm() {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HeadingSmall>Create Price</HeadingSmall>
          <FormControl label="Product">
            <Select
              options={
                products &&
                products.map((item) => {
                  return {
                    id: item.id,
                    label: item.display_name,
                  };
                })
              }
              labelKey="label"
              valueKey="id"
              placeholder="Product Name"
              maxDropdownHeight="300px"
              type="select"
              onChange={({ value }) => {
                setData("product_id", value[0] && value[0]?.id);
                setState((prev) => ({
                  ...prev,
                  current_product: value,
                }));
              }}
              value={state.current_product}
              size="default"
              searchable={true}
            />
          </FormControl>
          {props.dataType !== "standard" && (
            <>
              <FormControl label="Weight">
                <Input
                  type="number"
                  value={state.weight}
                  onChange={(e) => {
                    setData("weight", e.target.value);
                    const BASE = e.target.value;
                    setState((prev) => ({
                      ...prev,
                      weight: BASE,
                    }));
                  }}
                />
              </FormControl>
              <FormControl label="Weight Unit">
                <Select
                  options={CONSTANTS.WEIGHT}
                  labelKey="label"
                  valueKey="id"
                  placeholder="Weight Unit"
                  maxDropdownHeight="300px"
                  type="select"
                  onChange={({ value }) => {
                    setData("weight_unit", value[0] && value[0]?.id);
                    setState((prev) => ({
                      ...prev,
                      weight_unit: value,
                    }));
                  }}
                  value={state.weight_unit}
                  size="default"
                />
              </FormControl>
            </>
          )}
          {props.dataType !== "business" && (
            <FormControl label="Distance Unit">
              <Select
                options={CONSTANTS.DISTANCE}
                labelKey="label"
                valueKey="id"
                placeholder="Distance Unit"
                maxDropdownHeight="300px"
                type="select"
                onChange={({ value }) => {
                  setData("distance_unit", value[0] && value[0]?.id);
                  setState((prev) => ({
                    ...prev,
                    distance_unit: value,
                  }));
                }}
                value={state.distance_unit}
                size="default"
              />
            </FormControl>
          )}
          <FormControl label="Start Date - End Date">
            <DatePicker
              range
              value={state.current_date}
              onChange={({ date }) =>
                setState((prev) => ({
                  ...prev,
                  current_date: date,
                }))
              }
              placeholder="YYYY/MM/DD - YYYY/MM/DD"
              overrides={{
                CalendarHeader: {
                  style: /* istanbul ignore next */ ({ $theme }) => ({
                    zIndex: "10000",
                  }),
                },
              }}
            />
          </FormControl>
          <Tooltip description="This is the total distance a carrier would travel to complete the order">
            <FormControl label="Base Distance">
              <Input
                type="number"
                value={state.base_distance}
                onChange={(e) => {
                  setData("base_distance", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    base_distance: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="The minimum distance a carrier might travel that might exceed the base distance due to rerouting">
            <FormControl label="Min Distance">
              <Input
                type="number"
                value={state.min_distance}
                onChange={(e) => {
                  setData("min_distance", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    min_distance: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="The maximum distance a carrier might travel that might exceed the base distance due to rerouting">
            <FormControl label="Max Distance">
              <Input
                type="number"
                value={state.max_distance}
                onChange={(e) => {
                  setData("max_distance", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    max_distance: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="The total amount to be paid to the carrier on order completion">
            <FormControl label="Cost Base Price">
              <Input
                type="number"
                value={state.cost_base_price}
                onChange={(e) => {
                  setData("cost_base_price", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    cost_base_price: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="The unit price to be paid to the carrier on order completion">
            <FormControl label="Cost Unit Price">
              <Input
                type="number"
                value={state.cost_unit_price}
                onChange={(e) => {
                  setData("cost_unit_price", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    cost_unit_price: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="The total amount quatrix receives on order completion inclusive of carrier cost">
            <FormControl label="List Base Price">
              <Input
                type="number"
                value={state.list_base_price}
                onChange={(e) => {
                  setData("list_base_price", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    list_base_price: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="The unit amount Quatrix receives on order completion">
            <FormControl label="List Unit Price">
              <Input
                type="number"
                value={state.list_unit_price}
                onChange={(e) => {
                  setData("list_unit_price", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    list_unit_price: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="Price change due to fuel increase or inflation">
            <FormControl label="Price Surcharge">
              <Input
                type="number"
                value={state.price_surcharge}
                onChange={(e) => {
                  setData("price_surcharge", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    price_surcharge: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <Tooltip description="A set discount of the total price">
            <FormControl label="Price Discount">
              <Input
                type="number"
                value={state.price_discount}
                onChange={(e) => {
                  setData("price_discount", e.target.value);
                  const BASE = e.target.value;
                  setState((prev) => ({
                    ...prev,
                    price_discount: BASE,
                  }));
                }}
              />
            </FormControl>
          </Tooltip>
          <FormControl label="Is Active">
            <Checkbox
              checked={state.is_active}
              onChange={(e) => {
                const BASE = e.target.checked;
                setState((prev) => ({
                  ...prev,
                  is_active: BASE,
                }));
                setData("is_active", BASE);
              }}
              labelPlacement={"right"}
            >
              Check the box to make the pricing active
            </Checkbox>
          </FormControl>
          <Button isLoading={saving} type="submit">
            Create
          </Button>
        </form>
      </>
    );
  }

  React.useEffect(() => {
    if (close) {
      props.handleIsOpen(false);
    }
  }, [close]);

  return (
    <>
      <ContentDrawer
        renderContent={renderForm}
        isOpen={props.isOpen}
        setIsOpen={() => props.handleIsOpen(false)}
      />
    </>
  );
}

export default AddPrice;
