import React, { useState, useEffect, useContext, createContext } from "react";
//import { useJsonToCsv } from "react-json-csv";
import { CSVLink, CSVDownload } from "react-csv";
import axios from "axios";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import moment from "moment";
import momentTz from "moment-timezone";
import { Notification } from "baseui/notification";

const pontyClient = axios.create({
  baseURL: "https://traccar.pontypriddholdings.com/api",
  params: {
    token: "CUvnkkHeadingLargecbMVbESAixonoMgA9fPlPOYz",
  },
  withCredentials: true,
});

const quatrixClient = axios.create({
  baseURL: "https://traccar.quatrixglobal.com/api",
  params: {
    token: "kMjnf5pvecqb7bFS70voa4v8iVkJRMyN",
  },
  withCredentials: true,
});

export const ReportContext = createContext({
  generateSpeedReport: () => {},
  setDate: () => {},
  speedReports: [],
  devices: [],
  filteredDevice: [],
  setFilteredDevice: () => {},
});

export function ReportProvider(props) {
  const [speedReports, setSpeedReports] = useState([]);
  const [period, setPeriod] = useState({
    from: null,
    to: null,
  });
  const [fetching, setFetching] = useState(false);
  const [css] = useStyletron();
  const [devices, setDevices] = useState([]);
  const [filteredDevice, setFilteredDevice] = useState([]);
  const [message, setMessage] = useState(null);

  function utcToEat(date) {
    return moment(momentTz.tz(date, moment.tz.guess())).format("lll");
  }

  useEffect(() => {
    if (period.from && period.to) {
      getSpeedReport(period.from, period.to).then((res) => {
        if (res) {
          if (filteredDevice[0]?.id) {
            setSpeedReports(() => [
              ...res
                .filter((item) => item.deviceName === filteredDevice[0].id)
                .map((item) => {
                  return {
                    "Registation No": item.deviceName,
                    "Start Time (EAT)": `\"${utcToEat(item.startTime)}\"`,
                    "End Time (EAT)": `\"${utcToEat(item.endTime)}\"`,
                    "Max Speed (KPH)": `${parseFloat(
                      item.maxSpeed * 1.852
                    ).toFixed(2)}km/h`,
                    "Average Speed (KPH)": `${parseFloat(
                      item.averageSpeed * 1.852
                    ).toFixed(2)}km/h`,
                    Duration: `${new Date(item.duration * 1000)
                      .toISOString()
                      .substr(11, 8)}`,
                    "Start Latitude": item.startLat,
                    "Start Longitude": item.startLon,
                    "Start Address": `\"${item.startAddress}\"`,
                    "End Latitude": item.endLat,
                    "End Longitude": item.endLon,
                    "End Address": `\"${item.endAddress}\"`,
                    Event: "Speeding",
                  };
                }),
            ]);
          } else {
            setSpeedReports(() => [
              ...res.map((item) => {
                return {
                  "Registation No": item.deviceName,
                  "Start Time (EAT)": `\"${utcToEat(item.startTime)}\"`,
                  "End Time (EAT)": `\"${utcToEat(item.endTime)}\"`,
                  "Max Speed (KPH)": `${parseFloat(
                    item.maxSpeed * 1.852
                  ).toFixed(2)}km/h`,
                  "Average Speed (KPH)": `${parseFloat(
                    item.averageSpeed * 1.852
                  ).toFixed(2)}km/h`,
                  Duration: `${new Date(item.duration * 1000)
                    .toISOString()
                    .substr(11, 8)}`,
                  "Start Latitude": item.startLat,
                  "Start Longitude": item.startLon,
                  "Start Address": `\"${item.startAddress}\"`,
                  "End Latitude": item.endLat,
                  "End Longitude": item.endLon,
                  "End Address": `\"${item.endAddress}\"`,
                  Event: "Speeding",
                };
              }),
            ]);
          }
        }
      });
    }
  }, [period.from, period.to]);

  async function getSpeedReport(from, to) {
    setFetching(true);
    setSpeedReports(() => []);
    try {
      const response = await pontyClient.get(
        `/reports/trips?groupId=1&type=['deviceOverspeed']&from=${from}&to=${to}`
      );
      setFetching(false);

      return response.data;
    } catch (error) {
      setMessage("Problem getting speed reports, please try again");
      setFetching(false);
      setSpeedReports(() => []);
      return error;
    }
  }

  async function geoCode(lat, lon) {
    try {
      const response = await axios.get(
        `https:// api.openrouteservice.org/geocode/reverse?api_key=5b3ce3597851110001cf624829d8d1f96b5742548ac3ea51e97781f2&point.lon=${lon}&point.lat=${lat}`
      );

      return response.data.features[0]?.properties?.localadmin;
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    getDevices()
      .then((res) => {
        setDevices(() => res);
      })
      .catch((err) => {
        setMessage("Problem fetching devices");
      });

    return () => {
      setSpeedReports(() => []);
      setFetching(false);
      setDevices(() => []);
      setFilteredDevice(() => []);
    };
  }, []);

  async function getDevices() {
    await pontyClient.get(`/session`);

    try {
      const response = await pontyClient.get(`/devices?groupId=1`);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async function setDate(from, to) {
    const fromDate = new Date(from).toISOString();
    const toDate = new Date(to).toISOString();

    setPeriod((prev) => ({
      ...prev,
      from: fromDate,
      to: toDate,
    }));
  }

  //const { saveAsCsv } = useJsonToCsv();
  let headers = [
    {label:"Registation No", key:"Registation No"},
    {label:"Max Speed (KPH)", key:"Max Speed (KPH)"},
    {label:"Average Speed (KPH)", key:"Average Speed (KPH)"},
    {label:"Start Address", key:"Start Address"},
    {label: "End Address", key: "End Address"},
    {label: "Event", key: "Event"},
    {label:"Start Latitude", key:"Start Latitude"},
    {label:"Start Longitude",key: "Start Longitude"},
    {label: "End Latitude", key:"End Latitude"},
    {label:"End Longitude",key: "End Longitude"},
    {label:"Start Time (EAT)",key: "Start Time (EAT)"},
    {label:"End Time (EAT)", key:"End Time (EAT)"},
    {label:"Duration", key:"Duration"}
  ]
  console.log(fetching)
  console.log(speedReports.length)

  return (
    <ReportContext.Provider
      value={{
        speedReports,
        setDate,
        generateSpeedReport: getSpeedReport,
        devices,
        filteredDevice,
        setFilteredDevice,
      }}
    >
      {props.children}
      {message && (
        <Notification
          overrides={{
            Body: { style: { width: "auto" } },
          }}
          closeable
          kind="warning"
        >
          {message}
        </Notification>
      )}
      <br />

      {speedReports.length > 0 && fetching === false? <CSVLink
          data={speedReports}
          filename={`speed_reports_${new Date().getTime()}`}
          className="btn btn-primary"
          target="_blank"
          isLoading={fetching}
          style={{
            backgroundColor: '#000',
            color: '#fff',
            border: '2px solid black',
            padding: '14px 16px',
            lineHeight: '20px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-flex',
            fontFamily: 'system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif'
          }}
      >
        <span style={{
          marginRight:'0.25rem'
        }}>Export</span>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={css({
              color: "inherit",
              height: "20px",
              width: "20px",
            })}
            viewBox="0 0 20 20"
            fill="currentColor"
        >
          <path
              fillRule="evenodd"
              d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
              clipRule="evenodd"
          />
        </svg>
      </CSVLink> :<Button
            endEnhancer={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={css({
                  color: "inherit",
                  height: "20px",
                  width: "20px",
                })}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                  clipRule="evenodd"
                />
              </svg>
            }
            disabled={true}
      >
        Export
      </Button>}
      {/*<Button*/}
      {/*  // onClick={() =>*/}
      {/*  //   saveAsCsv({*/}
      {/*  //     data: speedReports,*/}
      {/*  //     fields: {*/}
      {/*  //       "Registation No": "Registation No",*/}
      {/*  //       "Max Speed (KPH)": "Max Speed (KPH)",*/}
      {/*  //       "Average Speed (KPH)": "Average Speed (KPH)",*/}
      {/*  //       "Start Address": "Start Address",*/}
      {/*  //       "End Address": "End Address",*/}
      {/*  //       Event: "Event",*/}
      {/*  //       "Start Latitude": "Start Latitude",*/}
      {/*  //       "Start Longitude": "Start Longitude",*/}
      {/*  //       "End Latitude": "End Latitude",*/}
      {/*  //       "End Longitude": "End Longitude",*/}
      {/*  //       "Start Time (EAT)": "Start Time (EAT)",*/}
      {/*  //       "End Time (EAT)": "End Time (EAT)",*/}
      {/*  //       Duration: "Duration",*/}
      {/*  //     },*/}
      {/*  //     filename: `speed_reports_${new Date().getTime()}`,*/}
      {/*  //   })*/}
      {/*  // }*/}
      {/*    onClick={()=>{*/}
      {/*      console.log(speedReports)*/}
      {/*      */}
      {/*    }}*/}
      {/*  endEnhancer={*/}
      {/*    <svg*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*      className={css({*/}
      {/*        color: "inherit",*/}
      {/*        height: "20px",*/}
      {/*        width: "20px",*/}
      {/*      })}*/}
      {/*      viewBox="0 0 20 20"*/}
      {/*      fill="currentColor"*/}
      {/*    >*/}
      {/*      <path*/}
      {/*        fillRule="evenodd"*/}
      {/*        d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"*/}
      {/*        clipRule="evenodd"*/}
      {/*      />*/}
      {/*    </svg>*/}
      {/*  }*/}
      {/*  disabled={speedReports.length > 0 ? false : true}*/}
      {/*  isLoading={fetching}*/}
      {/*>*/}
      {/*  Export*/}
      {/*</Button>*/}
      {/*<CSVDownload data={speedReports} target="_blank" />;*/}
    </ReportContext.Provider>
  );
}

export const useReport = () => {
  return useContext(ReportContext);
};
