// @flow

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { AppStateProvider } from "./context/app-context";
import Routes from "./routes";
import { setAxiosGlobal } from "./helpers/localstorage";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import assetMapping from "./asset-mapping";
import "./index.css";
import { LVPProvider } from "./context/local-value-persistence";

const engine = new Styletron();

setAxiosGlobal();

export default function App() {
  return (

      <LVPProvider>
        <AppStateProvider>
          <Helmet>
            <title>{assetMapping.label || "Operations"}</title>
            <link rel="icon" href={assetMapping.favicon} />
            <link rel="manifest" href={assetMapping.manifest} />
          </Helmet>
          <Toaster position="top-right" reverseOrder={false} />
          <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme}>
              <Router>
                <Routes />
              </Router>
            </BaseProvider>
          </StyletronProvider>
        </AppStateProvider>
      </LVPProvider>

  );
}
